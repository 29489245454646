import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/main.css'
import Directives from './directives/Directives'

import '@/plugins/veevalidate'
import VueCarousel from 'vue-carousel'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueTable from 'vuetable-2'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import Moment from 'vue-moment'
import MomentTimezone from 'moment-timezone'
import 'moment/locale/pt-br'
import GAuth from 'vue-google-oauth2'
import VueAnalytics from 'vue-analytics';


Vue.use(VueAnalytics, {
    id: 'UA-104796288-1',
    router
})

const gauthOption = {
    clientId: '50417625597-32maupdmvqeqj539ee9982d65750hvht.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)
Vue.config.productionTip = false

Vue.use(VCalendar)
Vue.use(VueCarousel)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY, loaderOptions: { autoHideBadge: true } })
Vue.use(Moment, { moment: MomentTimezone })
Vue.component('vuetable', VueTable)

Directives.register(Vue);



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
