'use strict'

import axios from 'axios'
import Login from '@/services/Landing/Login'
import Store from '@/store'
import Router from '@/router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



let config = {
    baseURL: process.env.VUE_APP_BASEURL
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
}



const _axios = axios.create(config)

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        let token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token
        }
        try {
            config.headers['ClientDateTimeISO'] = new Date().toISOString();
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            config.headers['ClientDateTime'] = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
            config.headers['x-is-wd'] = window.navigator.webdriver === undefined ? false : window.navigator.webdriver
            config.headers['painel-tutu'] = 'true';


            const geo = JSON.parse(localStorage.getItem('geolocation'));
            if (geo) {
                if (geo.latitude)
                    config.headers['x-geo-lat'] = geo.latitude;

                if (geo.longitude)
                    config.headers['x-geo-long'] = geo.longitude;

                if (geo.accuracy)
                    config.headers['x-geo-acc'] = geo.accuracy;
            }

        } catch {

        }

        /* useAppInsights().trackEvent({
             name: 'axios request',
             properties: { // accepts any type
                 request: JSON.stringify(config),
             }
         });*/

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data

        /* useAppInsights().trackEvent({
             name: 'axios request',
             properties: { // accepts any type
                 request: JSON.stringify(response),
             }
         });*/

        return response
    },
    async function (error) {
        // Do something with response error
        const originalRequest = error.config
        if (error.response.status === 401) {
            try {
                const grecaptcha = await this.$recaptcha('login')
                const response = (await Login.login({ refreshToken: localStorage.getItem('refreshToken'), grecaptcha })).data.data
                Store.dispatch('setLogin', response)
                return _axios(originalRequest)
            } catch (err) {
                Store.dispatch('setLogout')
                if (Router.currentRoute.name !== 'landing') Router.push({ name: 'landing', params: { expired: true } })
            }
        }
        return Promise.reject(error)
    }
)

export default _axios
