import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import moment from 'moment-timezone';
import { getCoordinates } from './plugins/geolocation'

// LANDING
const Landing = () =>
    import('@/views/Landing/Landing')
const Investor = () =>
    import('@/views/Landing/Investor/Investor')

const InvestorBackFriday2021 = () =>
    import('@/views/Landing/Investor/BlackFriday2021')

const Partner = () =>
    import('@/views/Landing/Partner/Partner')

const BePartner = () =>
    import('@/views/Landing/Partner/BePartner')

const Simulation = () =>
    import('@/views/Landing/Simulation/Simulation')
const BeInvestor = () =>
    import('@/views/Landing/Investor/BeInvestor')
const UnsubscribeEmail = () =>
    import('@/views/Landing/Investor/UnsubscribeEmail')
const ConfirmAccount = () =>
    import('@/views/Landing/Account/ConfirmAccount')
const ChangePassword = () =>
    import('@/views/Landing/Account/ChangePassword')
const InterestProposalGuarantee = () => import('@/views/Landing/Business/InterestProposalGuarantee')
// BUSINESS/TOMADOR
const BusinessLogin = () =>
    import('@/views/Business/Login')

const VideoCall = () =>
    import('@/views/Business/VideoCall/VideoCall')

const BusinessDashboard = () =>
    import('@/views/Business/Dashboard')
const BusinessOverview = () =>
    import('@/views/Business/Overview/Overview')
const BusinessAcceptConditions = () =>
    import('@/views/Business/Solicitation/AcceptConditions')
const Solicitation = () =>
    import('@/components/Business/Solicitation/Solicitation')
const BusinessProfile = () =>
    import('@/views/Business/Profile/ProfileForm')
const PersonalData = () =>
    import('@/components/Business/Form/PersonalData')
const CompanyData = () =>
    import('@/components/Business/Form/CompanyData')
const DirectorsData = () =>
    import('@/components/Business/Form/DirectorsData')
const DocumentsCentral = () =>
    import('@/components/Business/Form/DocumentsCentral')

// INVESTOR/INVESTIDOR
const InvestorLogin = () =>
    import('@/views/Investor/Login')
const InvestorDashboard = () =>
    import('@/views/Investor/Dashboard')
const InvestorOverview = () =>
    import('@/views/Investor/Overview/Overview')
const InvestorProfile = () =>
    import('@/views/Investor/Profile/Profile')
const InvestorMessages = () =>
    import('@/views/Investor/Messages/Messages')
const InvestorWallet = () =>
    import('@/views/Investor/Wallet/Wallet')
const InvestorSuitability = () =>
    import('@/views/Investor/Profile/Suitability')
const InvestorMyInvestments = () =>
    import('@/views/Investor/Investments/MyInvestments')
const InvestorOpportunity = () =>
    import('@/views/Investor/Opportunity/Opportunity')
const InvestorReports = () =>
    import('@/views/Investor/Reports/Reports')

// PARTNER/PARCEIRO
const PartnerLogin = () =>
    import('@/views/Partner/Login')
const PartnerDashboard = () =>
    import('@/views/Partner/Dashboard')
const PartnerProposals = () =>
    import('@/views/Partner/Proposals/Proposals')
const PartnerNewProposal = () =>
    import('@/views/Partner/Proposals/NewProposal')
const Fechamentos = () =>
    import('@/views/Partner/Fechamentos')
// SOCIO
const SCR = () =>
    import('@/views/Partner/Scr')

const TermosCondicoes = () =>
    import('@/views/Landing/Terms/TermsBusinessInvestor')
// SOCIO


import Links from '@/services/Landing/Links'

import SimulationService from '@/services/Landing/Simulation'


Vue.use(Router)

const needAuth = (next, path, role) => {
    if (Store.state.isLoggedIn && Store.state.roles.includes(role)) {
        next()
    } else {
        next({ path, replace: true })
    }
}

const needAnon = (next, path, role) => {
    if (Store.state.isLoggedIn && Store.state.roles.includes(role)) {
        next({ path, replace: true })
    } else {
        next()
    }
}

const testExpDate = () => {
    if (!!Store.state.expDate &&
        !moment(Store.state.expDate).utc().add(5, 'minutes').isAfter(moment().utc())) {
        console.log('logout efetuado por token expirado')
        //  Store.dispatch('notification', { type: 'error', message: `Token expirado - ${moment(Store.state.expDate).utc().add(5, 'minutes')} - ${moment().utc()}` });
        Store.dispatch('setLogout')

    }
}

const testPartnerAccess = () => {
    if (Store.state.wasPartnerAccess && !Store.state.isPartnerAccess) Store.dispatch('setLogout')
}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch((err) => {
        if (Router.isNavigationFailure(err)) {
            // resolve err
            return err
        }
        // rethrow error
        return Promise.reject(err)
    })
}


const router = new Router({
    mode: 'history',
    routes: [

        // LANDING

        {
            path: '/',
            name: 'landing',
            component: Landing,
            props: route => ({ expired: route.params.expired }),
            beforeEnter: (to, from, next) => {
                if (to.query.i) return next(`/investimento/solicitar?i=${to.query.i}`)
                return next()
            }
        },
        {
            path: '/r/:slug?',
            name: 'redirect-short-link',
            component: Landing,
            beforeEnter: async (to, from, next) => {
                console.log(to.params.slug);
                const res = (await Links.getLink(to.params.slug)).data;
                if (res.data)
                    location.href = res.data
            }
        },
        {
            path: '/emprestimo/:slug?',
            name: 'lending',
            component: Simulation
        },
        {
            path: '/termos-condicoes',
            name: 'terms',
            component: TermosCondicoes
        },
        {
            path: '/emprestimo/preaprovado/:userId/:solicitationId',
            name: 'preaprovado',
            component: Simulation
        },
        {
            path: '/emprestimo/preaprovado/:solicitationId',
            name: 'preaprovado1',
            component: Simulation,
            beforeEnter: async (to, from, next) => {
                console.log(to.params.solicitationId);
                const res = (await SimulationService.preApproved(to.params.solicitationId)).data;
                if (res.data)
                    next(`/emprestimo/preaprovado/${res.data.userId}/${to.params.solicitationId}`)
            }
        },
        {
            path: '/microcredito/:slug?',
            name: 'microcredit',
            component: Simulation,
            props: { microcredit: true }
        },
        {
            path: '/investidor/descadastrar-lista-email/:uId',
            name: 'unsubscribeEmail',
            component: UnsubscribeEmail
        },
        {
            path: '/proposta/interesse-proposta-com-garantia/:uId',
            name: 'interestProposalGuarantee',
            component: InterestProposalGuarantee
        },
        {
            path: '/investidor/descastrar-lista-email/:uId',
            name: 'unsubscribeEmail2',
            component: UnsubscribeEmail
        },
        {
            path: '/investimento',
            name: 'investor',
            component: Investor
        },
        /*{
            path: '/blackfriday',
            name: 'blackfriday',
            component: InvestorBackFriday2021
        },*/
        {
            path: '/parceiros',
            name: 'parceiros',
            component: Partner
        },
        {
            path: '/parceiros/cadastrar',
            name: 'parceirosCadastrar',
            component: BePartner
        },
        {
            path: '/investimento/solicitar',
            name: 'apply',
            component: BeInvestor,
            props: { req: 'investidor' }
        },
        {
            path: '/tomador/recuperar-senha',
            name: 'recoverPasswordBusiness',
            component: ChangePassword,
            props: { req: 'tomador' }
        },
        {
            path: '/tomador/visita-video-chamada/:uId',
            name: 'videoCall',
            component: VideoCall,
            props: { req: 'tomador' }
        },
        {
            path: '/parceiro/recuperar-senha',
            name: 'recoverPasswordBusiness',
            component: ChangePassword,
            props: { req: 'site/parceiro' }
        },
        {
            path: '/investidor/recuperar-senha',
            name: 'recoverPasswordInvestor',
            component: ChangePassword,
            props: { req: 'investidor' }
        },
        {
            path: '/tomador/confirmar-conta',
            name: 'confirmAccountBusiness',
            component: ConfirmAccount,
            props: { req: 'tomador' }
        },
        {
            path: '/investidor/confirmar-conta',
            name: 'confirmAccountInvestor',
            component: ConfirmAccount,
            props: { req: 'investidor' }
        },

        // BUSINESS/TOMADOR

        {
            path: '/tomador/login',
            name: 'businessLogin',
            component: BusinessLogin,
            //beforeEnter: (to, from, next) => needAnon(next, '/tomador/painel', 'tomador')
        },
        // SOCIO
        {
            path: '/socio/aceitar-condicoes',
            name: 'acceptPartnerConditions',
            component: BusinessAcceptConditions,
            props: { isBusinessPartnerAccess: true },
            beforeEnter: (to, from, next) => needAuth(next, '/tomador/login', 'socio_tomador')
        },
        {
            path: '/tomador',
            component: BusinessDashboard,
            redirect: '/tomador/painel',
            beforeEnter: (to, from, next) => needAuth(next, '/tomador/login', 'tomador'),
            children: [{
                path: 'painel',
                name: 'businessdashboard',
                component: BusinessOverview
            },
            {
                path: 'solicitacao/:id',
                name: 'solicitation',
                component: Solicitation
            },
            {
                path: 'aceitar-condicoes/:id?',
                name: 'acceptConditions',
                component: BusinessAcceptConditions
            },
            {
                path: 'perfil',
                name: 'businessprofile',
                component: BusinessProfile,
                redirect: '/tomador/perfil/dados-empresa',
                children: [{
                    path: 'dados-pessoais',
                    name: 'personaldata',
                    component: PersonalData
                },
                {
                    path: 'dados-empresa',
                    name: 'companydata',
                    component: CompanyData
                },
                {
                    path: 'quadro-socios',
                    name: 'directorsdata',
                    component: DirectorsData
                },
                {
                    path: 'documentos',
                    name: 'documentos',
                    component: DocumentsCentral
                }
                ]
            }
            ]
        },

        // INVESTOR/INVESTIDOR


        {
            path: '/semana-consumidor-2021',
            name: 'semanaConsumidor2021',
            component: InvestorLogin,
            // beforeEnter: (to, from, next) => needAnon(next, '/investidor/painel', 'investidor')
            beforeEnter: () => {
                window.location.href = 'https://migracao.tutudigital.com.br/painel-investidor/semana-consumidor-2021';
            }
        },

        {
            path: '/investidor/login',
            name: 'investorLogin',
            component: InvestorLogin,
            //beforeEnter: (to, from, next) => needAnon(next, '/investidor/painel', 'investidor')
            /*beforeEnter: () => {
                window.location.href = 'https://migracao.tutudigital.com.br/painel-investidor';
            }*/
        },

        {
            path: '/painel-investidor',
            name: 'investorLoginRedirect',
            component: InvestorLogin,
            // beforeEnter: (to, from, next) => needAnon(next, '/investidor/painel', 'investidor')
            beforeEnter: () => {
                window.location.href = 'https://tutudigital.com.br/investidor/login';
            }
        },

        {
            path: '/investidor',
            component: InvestorDashboard,
            redirect: '/investidor/painel',
            beforeEnter: (to, from, next) => needAuth(next, '/investidor/login', 'investidor'),
            children: [{
                path: 'painel',
                name: 'investordashboard',
                component: InvestorOverview
            },
            {
                path: 'painel/oportunidade/:id',
                name: 'investoropportunity',
                component: InvestorOpportunity,
                props: true
            },
            {
                path: 'meus-investimentos',
                name: 'myinvestments',
                component: InvestorMyInvestments
            },
            {
                path: 'carteira',
                name: 'carteira',
                component: InvestorWallet
            },
            {
                path: 'perfil',
                name: 'investorprofile',
                component: InvestorProfile
            },
            {
                path: 'suitability',
                name: 'investorsuitability',
                component: InvestorSuitability
            },
            {
                path: 'mensagens',
                name: 'investormessages',
                component: InvestorMessages
            },
            {
                path: 'relatorios',
                name: 'investorreports',
                component: InvestorReports
            },
            ]
        },

        // PARCEIRO

        {
            path: '/parceiro/login',
            name: 'partnerLogin',
            component: PartnerLogin,
            //beforeEnter: (to, from, next) => needAnon(next, '/parceiro/painel', 'parceiro_tomador')
        },
        {
            path: '/parceiro',
            component: PartnerDashboard,
            redirect: '/parceiro/painel',
            beforeEnter: (to, from, next) => needAuth(next, '/parceiro/login', 'parceiro_tomador'),
            children: [{
                path: 'painel',
                name: 'partnerdashboard',
                component: PartnerProposals
            },
            {
                path: 'nova-proposta',
                name: 'partnernewproposal',
                component: PartnerNewProposal
            },
            {
                path: 'fechamentos',
                name: 'fechamentos2',
                component: Fechamentos
            },
            {
                path: 'scr',
                name: 'scr',
                component: SCR,
                beforeEnter: (to, from, next) => {
                    if (!Store.state.partner.scr)
                        window.location.href = '/parceiro/painel';
                    next()
                }
            }
            ]
        },

        // SÓCIO

        // ETC

        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    getCoordinates();

    testExpDate()
    if (to.path.split('/').includes('tomador')) testPartnerAccess()
    return next()
})

export default router