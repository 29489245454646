import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

import Dictionary from '@/validations/dictionary'
import CPF from '@/validations/cpf'
import CNPJ from '@/validations/cnpj'
import FullName from '@/validations/fullName'

Vue.use(VeeValidate, { locale: 'pt', dictionary: Dictionary, fieldsBagName: 'formFields' })
Validator.extend('cpf', CPF)
Validator.extend('cnpj', CNPJ)
Validator.extend('fullName', FullName)
