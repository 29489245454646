import Vue from 'vue'
import Vuex from 'vuex'
import createMutationsSharer from "vuex-shared-mutations";
import jwt_decode from "jwt-decode";
import { EventBus } from '@/event-bus.js';
import * as SignalR from '@microsoft/signalr';

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createMutationsSharer({ predicate: ["setNotification", 'setSCR', 'setEmail', "clearNotification", "showNotifications", "setBalance", "setContaIuguAtiva", "setToken", "setRefreshToken", "setExpDate", "setRoles", "setLogout", "setBusiness", "setInvestorName", "setAvatar", "setGeolocation", "setPartner", "initSignalR"] })],
    state: {
        notifications: [],
        showNotifications: false,
        geolocation: null,
        isLoggedIn: !!localStorage.getItem('token'),
        expDate: localStorage.getItem('expDate'),
        roles: localStorage.getItem('roles'),
        email: localStorage.getItem('email'),
        isPartnerAccess: false,
        wasPartnerAccess: localStorage.getItem('wasPartnerAccess'),
        isBusinessPartnerAccess: localStorage.getItem('isBusinessPartnerAccess'),
        token: localStorage.getItem('token'),
        business: {
            incompleteProfile: true,
            fantasyName: '',
            emailConfirmed: true,
            step: 0,
            docsEmAnalise: false
        },
        investor: {
            name: '',
            balance: 0,
            avatar: localStorage.getItem('avatar') != null || process.env.VUE_APP_AVATAR_URL,
            contaIuguAtiva: false
        },
        partner: {
            name: 'Teste',
            email: localStorage.getItem('email'),
            scr: localStorage.getItem('partner::scr')
        }
    },
    mutations: {
        setGeolocation(state, geo) {
            state.geolocation = geo
            localStorage.setItem('geolocation', JSON.stringify({ latitude: geo.latitude, longitude: geo.longitude, accuracy: geo.accuracy }))
        },
        setToken(state, token) {
            state.isLoggedIn = !!token
            state.token = token;
            localStorage.setItem('token', `Bearer ${token}`)
        },
        setRefreshToken(state, refreshToken) {
            localStorage.setItem('refreshToken', refreshToken)
        },
        setExpDate(state, expDate) {
            state.expDate = expDate
            localStorage.setItem('expDate', expDate)
        },
        setRoles: (state, roles) => {
            state.roles = roles.join(',')
            localStorage.setItem('roles', roles)
        },
        setLogout(state) {
            state.isLoggedIn = null
            state.expDate = null
            state.roles = []
            state.isPartnerAccess = null
            state.email = null
            state.isBusinessPartnerAccess = null
            state.wasPartnerAccess = null
            state.business = {
                incompleteProfile: true,
                fantasyName: '',
                emailConfirmed: true,
                step: 0,
                docsEmAnalise: false
            }
            state.investor = {
                name: '',
                balance: 0,
                avatar: process.env.VUE_APP_AVATAR_URL
            }
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('expDate')
            localStorage.removeItem('roles')
            localStorage.removeItem('avatar')
            localStorage.removeItem('wasPartnerAccess')
            localStorage.removeItem('email')

            // this.$meter.clearUserContext();

        },
        setPartner: (state, partner) => {
            state.partner = partner;
        },
        setNotification: (state, notification) => {
            state.notifications.push(notification)
            state.showNotifications = true
        },
        clearNotification: state => {
            state.notifications.shift()
            state.showNotifications = false
        },
        showNotifications: state => {
            state.showNotifications = true
        },
        setBusiness(state, business) {
            console.log(business)

            state.business = business
        },
        setSCR(state, scr) {
            state.partner.scr = scr;
            localStorage.setItem('partner::scr', scr)
        },
        setBalance: (state, balance) => {
            state.investor.balance = balance
        },
        setInvestorName: (state, name) => {
            state.investor.name = name
        },
        setEmail: (state, email) => {
            state.email = email
            localStorage.setItem('email', email)

        },
        setContaIuguAtiva: (state, contaIuguAtiva) => {
            state.investor.contaIuguAtiva = contaIuguAtiva
        },
        setAvatar: (state, avatar) => {
            if (avatar) {
                state.investor.avatar = avatar
                localStorage.setItem('avatar', avatar)
            }
        },
        setPartnerAccess: (state, isPartnerAccess) => {
            state.isPartnerAccess = isPartnerAccess
            state.wasPartnerAccess = isPartnerAccess
            localStorage.setItem('wasPartnerAccess', isPartnerAccess)
        },
        setBusinessPartnerAccess: (state, isBusinessPartnerAccess) => {
            localStorage.setItem('isBusinessPartnerAccess', isBusinessPartnerAccess)
            state.isPartnerAccess = isBusinessPartnerAccess
        }
    },
    actions: {
        async initSignalR({ dispatch }) {
            const startConnection = async (connection) => {
                try {
                    await connection.start();
                    console.info('Conexão com servidor da Tutu Digital realizada com sucesso', connection);
                } catch (err) {
                    console.error('Erro ao conectar:', err);
                    setTimeout(() => startConnection(connection), 5000); // Tenta reconectar a cada 5 segundos
                }
            };

            const connection = new SignalR.HubConnectionBuilder()
                .withUrl(`${process.env.VUE_APP_BASEURL.replace('/api/', '')}/hub`, { accessTokenFactory: () => this.state.token.replace('Bearer ', '') })
                .withAutomaticReconnect([0, 2000, 10000, 30000]) // Opcional: você pode especificar o intervalo de reconexão
                .build();

            connection.on("NovaTransacaoWalletInvestidor", () => {
                console.log("NovaTransacaoWalletInvestidor")
                EventBus.$emit('novaTransacaoWallet');
            });

            connection.onclose(async () => {
                console.log('Conexão perdida. Tentando reconectar...');
                await startConnection(connection);
            });

            await startConnection(connection);
        },

        setLogin({ commit }, data) {
            commit('setToken', data.bearerToken)
            commit('setRefreshToken', data.refreshToken)
            commit('setRoles', data.isFromPartner ? ['tomador'] : data.roles)
            commit('setExpDate', data.expiration)

            if (data.isFromPartner) commit('setPartnerAccess', data.isFromPartner)
            if (data.cnpj) commit('setBusinessPartnerAccess', true)

            if (data.bearerToken) {
                var jwtDecoded = jwt_decode(data.bearerToken);
                commit('setEmail', jwtDecoded.sub)
                commit('setSCR', jwtDecoded.permission && (jwtDecoded.permission === 'solicitarSCR' || jwtDecoded.includes('solicitarSCR')))
                // this.$meter.setUserContext({ authenticatedUserId: jwtDecoded.sub, accountId: jwtDecoded.id, storeInCookie: true });
            }
        },
        setLogout({ commit }) {
            commit('setLogout')
        },
        setGeolocation({ commit }, geo) {
            commit('setGeolocation', geo)
        },
        setBusiness({ commit }, business) {
            commit('setBusiness', business)
        },
        setBalance({ commit }, balance) {
            commit('setBalance', balance)
        },
        setPartner({ commit }, partner) {
            commit('setPartner', partner)
        },
        setInvestorName({ commit }, name) {
            commit('setInvestorName', name)
        },
        setContaIuguAtiva({ commit }, contaIuguAtiva) {
            commit('setContaIuguAtiva', contaIuguAtiva)
        },
        setAvatar({ commit }, avatar) {
            commit('setAvatar', avatar)
        },
        notification({ commit }, { type, message }) {
            const notification = { type, message }
            commit('setNotification', notification)
        },
        notificationErrors({ commit }, objErros) {
            const notification = { type: 'error', message: typeof (objErros) == 'object' ? objErros[Object.keys(objErros)[0]] : objErros.map(x => x[Object.keys(x)[0]]) }
            commit('setNotification', notification)
        },
        clearNotification({ commit, state }) {
            commit('clearNotification')
            setTimeout(() => {
                if (state.notifications.length) { commit('showNotifications') }
            }, 500)
        }
    }
})