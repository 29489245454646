<template>
  <div id="app">
    <transition name="fade">
      <router-view/>
    </transition>

    <!-- SNACKBAR -->
    <div
      v-show="$store.state.showNotifications"
      :class="`snackbar snackbar_${notification.type}`"
      role="alert"
    >
      <span
        :class="`snackbar_type snackbar_type_${notification.type}`"
      >
        <i :class="`fas fa-${notification.type === 'success' ? 'check': 'times'} text-lg`"></i>
      </span>
      <span class="snackbar_text">
        {{notification.message}}
      </span>
      <i class="snackbar_close fas fa-times" @click="$store.dispatch('clearNotification')"></i>
    </div>
  </div>
</template>

<script>
import { setTimeout, clearTimeout } from 'timers'

export default {
  name: 'App',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    notification () {
      return this.$store.state.showNotifications ? this.$store.state.notifications[0] : {}
    }
  },
  mounted () {
    this.$store.watch(
      state => state.showNotifications,
      value => {
        if (value) this.timer = setTimeout(() => this.$store.dispatch('clearNotification'), 5000)
        else clearTimeout(this.timer)
        return value
      }
    )
  }
}
</script>

<style lang="sass">

.container
  @apply mx-auto px-4
  @screen md
    @apply px-10
  @screen lg
    @apply flex flex-col px-16
  @screen xl
    @apply flex flex-col px-40

.snackbar
  @apply z-50 p-2 w-10/12 items-center leading-normal rounded-lg flex fixed bottom-0 inset-x-0 mx-auto mb-12 shadow-md text-sm
  min-height: 2.5rem
  -webkit-animation: slidefadein .5s, slidefadeout .5s 6s
  animation: slidefadein .5s, slidefadeout .5s 6s
  @screen lg
    @apply inline-flex w-1/3 text-base
    min-height: 3rem
  &_success
    @apply bg-primary text-white
  &_error
    @apply bg-red-600 text-white

.snackbar_type
  @apply mx-2
  &_success
    @apply text-secondary
  &_error
    @apply text-white

.snackbar_text
  @apply font-semibold mr-2 text-left flex-auto

.snackbar_close
  @apply fill-current opacity-75 h-4 w-4 cursor-pointer
  &_success
    @apply text-secondary
  &_error
    @apply text-white

@-webkit-keyframes slidefadein
  from
    margin-bottom: 0
    opacity: 0
  to
    margin-bottom: 3rem
    opacity: 1

@keyframes slidefadein
  from
    margin-bottom: 0
    opacity: 0
  to
    margin-bottom: 3rem
    opacity: 1

@-webkit-keyframes slidefadeout
  from
    margin-bottom: 3rem
    opacity: 1
  to
    margin-bottom: 0
    opacity: 0

@keyframes slidefadeout
  from
    margin-bottom: 3rem
    opacity: 1
  to
    margin-bottom: 0
    opacity: 0

.fade-enter-active, .fade-leave-active
  opacity: 0

.fade-enter-active
.fade-leave-active
  transition: opacity .3s

.fade-enter,
.fade-leave-to
  opacity: 0

.slide-enter-active
  -moz-transition-duration: 0.3s
  -webkit-transition-duration: 0.3s
  -o-transition-duration: 0.3s
  transition-duration: 0.3s
   -moz-transition-timing-function: ease-in
   -webkit-transition-timing-function: ease-in
   -o-transition-timing-function: ease-in
   transition-timing-function: ease-in

.slide-leave-active
  -moz-transition-duration: 0.3s
  -webkit-transition-duration: 0.3s
  -o-transition-duration: 0.3s
  transition-duration: 0.3s
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-enter-to, .slide-leave
  max-height: 300px
  overflow: hidden
  opacity: 1

.slide-enter, .slide-leave-to
  max-height: 0
  overflow: hidden
  opacity: 0

body.modal-open
  overflow: hidden


.hide_on_mobile
  @apply hidden
  @screen lg
    @apply block

</style>
