const capitalizeName = (str) => {
  const splitStr = str.replace(/\s{2,}/g, ' ').toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    if (i === 0 || (splitStr[i].length > 2 && (splitStr[i] !== 'das' && splitStr[i] !== 'do' && splitStr[i] !== 'de' && splitStr[i] !== 'dos'))) {
      splitStr[i] = (splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)).trim()
    }
  }
  return splitStr.join(' ')
}

export default {
  methods: {
    capitalizeName
  }
}
