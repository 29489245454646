const fullName = (name) => {
  const arrSplit = name.replace(/\s{2,}/g, ' ').split(' ')
  return !(arrSplit.length < 2 || arrSplit[0].length < 2 || arrSplit[1].length < 2)
}

export default {
  validate (name, args) {
    return fullName(name)
  }
}
