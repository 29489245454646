/* eslint-disable */
import attributes from './attributes'
const dictionary = {
  pt: {
    attributes: {
      ...attributes
    },
    messages: {
      _default: (e) => 'O valor do campo ' + decamelize(e) + ' não é válido.',

      after: (e, o) => 'O campo ' + decamelize(e) + ' deve estar depois do campo ' + o[0] + '.',

      alpha_dash: (e) => 'O campo ' + decamelize(e) + ' deve conter letras, números e traços.',

      alpha_num: (e) => 'O campo ' + decamelize(e) + ' deve conter somente letras e números.',

      alpha_spaces: (e) => 'O campo ' + decamelize(e) + ' só pode conter caracteres alfabéticos e espaços.',

      alpha: (e) => 'O campo ' + decamelize(e) + ' deve conter somente letras.',

      before: (e, o) => 'O campo ' + decamelize(e) + ' deve estar antes do campo ' + o[0] + '.',

      between: (e, o) => 'O campo ' + decamelize(e) + ' deve estar entre ' + o[0] + ' e ' + o[1] + '.',

      cnpj: (e, o) => 'CNPJ inválido',

      confirmed: (e, o) => 'As senhas não coincidem.',

      cpf: (e, o) => 'CPF inválido',

      credit_card: (e) => 'O campo ' + decamelize(e) + ' é inválido.',

      date_between: (e, o) => 'O campo ' + decamelize(e) + ' deve estar entre ' + o[0] + ' e ' + o[1] + '.',

      date_format: (e, o) => 'Digite uma data válida.',

      decimal: (e, o) => {
        void 0 === o && (o = [])
        var n = o[0]
        return void 0 === n && (n = '*'), 'O campo ' + decamelize(e) + ' deve ser numérico e deve conter ' + (n && n !== '*' ? n : '') + ' casas decimais.'
      },
      digits: (e, o) => 'O campo deve conter ' + o[0] + ' dígitos.',

      dimensions: (e, o) => 'O campo ' + decamelize(e) + ' deve ter ' + o[0] + ' pixels de largura por ' + o[1] + ' pixels de altura.',

      email: (e) => 'Digite um e-mail válido.',

      ext: (e) => 'O campo ' + decamelize(e) + ' deve ser um arquivo válido.',

      fullName: (e) => 'Informe o ' + decamelize(e) + '.',

      image: (e) => 'O campo ' + decamelize(e) + ' deve ser uma imagem.',

      included: (e) => 'O campo ' + decamelize(e) + ' deve ter um valor válido.',

      integer: (e) => 'O campo ' + decamelize(e) + ' deve ser um número inteiro.',

      ip: (e) => 'O campo ' + decamelize(e) + ' deve ser um endereço IP válido.',

      is: (e) => 'O campo ' + decamelize(e) + ' tem um valor inválido.',

      length: (e, o) => {
        let n = o[0]
        let r = o[1]
        return r ? 'O tamanho do campo ' + decamelize(e) + ' está entre ' + n + ' e ' + r + '.' : 'O tamanho do campo ' + decamelize(e) + ' deve ser ' + n + '.'
      },
      max: (e, o) => 'Máximo de ' + o[0] + ' caracteres.',

      max_value: (e, o) => 'O valor máximo é de ' + o[0] + '.',

      mimes: (e) => 'O campo ' + decamelize(e) + ' deve ser um tipo de arquivo válido.',

      min: (e, o) => 'Mínimo de ' + o[0] + ' caracteres.',

      min_value: (e, o) => 'O valor mínimo é de ' + o[0] + '.',

      excluded: (e) => 'O campo ' + decamelize(e) + ' deve ser um valor válido.',

      numeric: (e) => 'O campo ' + decamelize(e) + ' deve conter apenas números',

      regex: (e) => 'O campo ' + decamelize(e) + ' possui um formato inválido.',

      required: (e) => 'O campo ' + decamelize(e) + ' é obrigatório.',

      size: (e, o) => {
        let n; let r; let t; let a = o[0]
        return 'O campo ' + decamelize(e) + ' deve ser menor que ' + (n = a, r = 1024, t = (n = Number(n) * r) === 0 ? 0 : Math.floor(Math.log(n) / Math.log(r)), 1 * (n / Math.pow(r, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t]) + '.'
      },

      url: (e) => 'O campo ' + decamelize(e) + ' não é uma URL válida.'
    }
  }
}

const decamelize = str => {
  const separator = ' '
	return str
      .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
      .toLowerCase();
}

export default dictionary
