import api from '@/plugins/axios'

const base = 'v1/site/emprestimo/'

export default {
  getReasons() {
    return api.get(base + 'motivos')
  },
  new(data) {
    return api.post(base + 'cadastro', data)
  },
  status(code) {
    return api.get(base + `status-analise/${code}`)
  },
  preApproved(code) {
    return api.get(base + `pre-aprovado/${code}`)
  },
  getPartnerData(slug) {
    return api.get(`v1/site/parceiro/buscar/${slug}`)
  },
  createPassword(data) {
    return api.post(base + 'pre-aprovado/criar-senha', data)
  },
  getGiveUpReasons() {
    return api.get(base + 'motivos-desistencia')
  },
  giveUp(data) {
    return api.post(base + 'desistir', data)
  },
  interestProposalGuarantee(id) {
    return api.patch(base + `registrar-interesse-nova-proposta-garantia/${id}`)
  }
}
