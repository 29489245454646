import api from '@/plugins/axios'

const base = 'auth/'

export default {
  login (data) {
    return api.post(`${base}login`, data)
  },
  loginFacebook (token) {
    return api.get(`${base}auth-facebook?accessToken=${token}`)
  },
  loginGoogle (token) {
    return api.get(`${base}auth-google?accessToken=${token}`)
  }
}
