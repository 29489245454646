export default {
  razaoSocial: 'razão social',
  nomeComercialFantasia: 'nome comercial fantasia',
  ramoAtividade: 'ramo atividade',
  inscricaoEstadual: 'inscrição estadual',
  faturamentoUltimos90Dias: 'faturamento últimos 90 dias',
  capitalSocial: 'capital social',
  vendaDoUltimoExercicio: 'vendas do último exercício',
  comprasUltimoExercicio: 'compras do último exercício',
  telefone: 'telefone',
  email: 'email',
  dataConstituicao: 'data constituição',
  principaisProdutosVendidos: 'principai produtos vendidos',
  quantidadeSocios: 'quantidade de sócios',
  quantidadeEmpregados: 'quantidade de empregados',
  website: 'website',
  enderecoSedeSocial_CEP: 'cep',
  enderecoSedeSocial_Logradouro: 'logradouro',
  enderecoSedeSocial_Numero: 'número',
  enderecoSedeSocial_Bairro: 'bairro',
  enderecoSedeSocial_Complemento: 'complemento',
  enderecoSedeSocial_UF: 'uf',
  enderecoSedeSocial_Cidade: 'cidade',
  endereco_CEP: 'cep',
  endereco_Logradouro: 'logradouro',
  endereco_Numero: 'número',
  endereco_Bairro: 'bairro',
  endereco_Complemento: 'complemento',
  endereco_UF: 'uf',
  endereco_Cidade: 'cidade',
  nomeFinanceiro: 'nome financeiro',
  emailFinanceiro: 'email financeiro',
  telefoneFinanceiro: 'telefone financeiro',
  referenciaComercial1_Nome: 'nome referência comercial',
  referenciaComercial1_Telefone: 'telefone referência comercial',
  referenciaComercial2_Nome: 'nome referência comercial',
  referenciaComercial2_Telefone: 'telefone referência comercial',
  referenciaPessoal1_Nome: 'nome referência pessoal',
  referenciaPessoal1_Telefone: 'telefone referência pessoal',
  referenciaPessoal2_Nome: 'nome referêncial pessoal',
  referenciaPessoal2_Telefone: 'telefone referêncial pessoal',
  contaBancaria1_InstituicaoFerbranId: 'instituição ferbran',
  contaBancaria1_Agencia: 'agência',
  contaBancaria1_Conta: 'conta',
  contaBancaria1_TipoConta: 'tipo da conta',
  contaBancaria2_InstituicaoFerbranId: 'instituição ferbran',
  contaBancaria2_Agencia: 'agência',
  contaBancaria2_Conta: 'conta',
  contaBancaria2_TipoConta: 'tipo da conta',
  termos: 'temos',
  nomeCompleto: 'nome completo',
  cpf: 'cpf',
  dataNascimento: 'data nascimento',
  nacionalidade: 'nacionalidade',
  naturalidade: 'naturalidade',
  rg: 'rg',
  dataExpedicaoRg: 'data de expedição do rg',
  orgaoExpedicaoRG: 'orgão expedição do rg',
  ufExpedicaoRg: 'uf expedição do rg',
  sexo: 'sexo',
  estadoCivil: 'estado civil',
  nomeConjugeCompanheiro: 'nome do cônjuge',
  celular: 'celular',
  telefoneResidencial: 'telefone residencial',
  tipoResidencia: 'tipo de residência',
  tempoResidencia: 'tempo na residência',
  tepoResidenciaAnosMeses: 'tempo na residência em anos ou meses',
  nomeMae: 'nome da mãe',
  nomePai: 'nome do pai',
  cargo: 'cargo',
  salario: 'salário',
  porcentagemParticipacao: 'porcentagem de participação',
  socioAdministrador: 'sócio administrador',
  responsavelLegalProcurador: 'responsável legal',
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
  pessoaPoliticamenteExposta: 'pessoa politicamente exposta',
  sms: 'sms',
  whatsApp: 'whatsApp',
  value: 'valor',
  valorDispostoInvestir6meses: 'valor disposto à investir',
  bancoId: 'instituição ferbran',
  tipoConta: 'tipo de conta',
  agencia: 'agência',
  digitoAgencia: 'dígito agência',
  conta: 'conta',
  digitoConta: 'dígito conta',
  'autorizacaoContato.email': 'autorização contato por email',
  'autorizacaoContato.sms': 'autorização contato por sms',
  'autorizacaoContato.telefone': 'autorização contato por telefone',
  'autorizacaoContato.whatsApp': 'autorização contato por whatsApp',
  apelido: 'apelido',
  telefoneContatoDireto: 'telefone para contato',
  orgaoExpeditor: 'orgão expeditor',
  dataExpedicaoRG: 'data de expedição do rg',
  cidadeNascimento: 'cidade de nascimento',
  rendaMensalAproximada: 'renda mensal aproximada',
  patrimonioAproximado: 'patrimônio aproximado',
  senhaAntiga: 'senha antiga',
  senha: 'senha',
  confirmacaoSenha: 'confirmação senha',
  nome: 'nome',
  contact_email: 'email contato',
  mensagem: 'mensagem',
  recuperarEmail: 'email',
  motivoDesistenciaId: 'motivo da desistência',
  observacoes: 'observações',
  aceitoReceberNovasPropostasNoFuturo: 'aceito receber novas propostas no futuro',
  nomeEmpresa: 'nome da empresa',
  emailContato: 'email contato',
  faturamentoMedioMensal: 'faturamento médio mensal',
  cnpj: 'cnpj',
  motivoSolicitacaoId: 'motivo da solicitação',
  possuiNegativacao: 'possuí negativação',
  contact: 'contato',
  termosPrivacidade: 'termos de privacidade',
  password: 'senha',
  adminEmail: 'email administrador',
  numeroTelefone: 'telefone',
  codigoEnviadoPorSMS: 'código de autenticação',
  aprovado: 'termo de condições',
  codigoConviteCampanha: 'código do convite',
  valorEmprestimoDesejado: 'valor desejado para emprestímo',
  prazoDesejado: 'prazo desejado',
  enviarEmails: 'enviar email',
  microcredito: 'microcrédito'
}
