import capitalizeName from "../mixins/capitalizeName"

export default {
    register(Vue) {
        Vue.directive('uppercase', {
            bind(el, binding, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value)
                })
            }
        })

        Vue.directive('trim', {
            bind(el, binding, vnode) {
                el.addEventListener('change', (e) => {
                    e.target.value = e.target.value.trim()
                    vnode.componentInstance.$emit('input', e.target.value)
                })
            }
        })

        Vue.directive('lowercase', {
            bind(el, binding, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toLowerCase()
                    vnode.componentInstance.$emit('input', e.target.value)
                })
            }
        })


        Vue.directive('capitalizeName', {
            bind(el, binding, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = capitalizeName.methods.capitalizeName(e.target.value)
                    vnode.componentInstance.$emit('input', e.target.value)
                })
            }
        })
    }
}