const isCPF = (cpf) => {
  const isInvalid = (cpf, rest, pos) => rest !== parseInt(cpf.substring(pos, pos + 1))

  const sumDigit = (cpf, digit) => 11 - (cpf.substring(0, digit).split('').reduce((acc, curr, index) => {
    acc += parseInt(curr) * ((digit + 1) - index)
    return acc
  }, 0) % 11)

  const getRest = sum => sum > 9 ? 0 : sum

  if (!(typeof cpf === 'string')) return false
  cpf = cpf.replace(/[\D]/gi, '')

  if (!cpf.match(/^\d+$/)) return false
  if (cpf === '00000000000' || cpf.length !== 11) return false
  if (isInvalid(cpf, getRest(sumDigit(cpf, 9)), 9)) return false
  if (isInvalid(cpf, getRest(sumDigit(cpf, 10)), 10)) return false
  return true
}

export default {
  validate (cpf, args) {
    return isCPF(cpf)
  }
}
